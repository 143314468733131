/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

:root {
  --background: url("/particles-bg.webp");
  --backgroundmobile: url("https://imagedelivery.net/9otR1sBY_9ZFhJikSNrX6g/fe8a46a0-5d1a-4008-9a76-db6a933b6200/768x432");
  --tech-dark-color: #02050a;
  --main-bg-gradient-m: linear-gradient(180deg, #4e3572 0%, #733c64 100%);
  --main-bg-gradient-d: #523f86;
  --bg-01: linear-gradient(180deg, #4e3572 0%, #733c64 100%);
  --bg-02: linear-gradient(180deg, #4e3572 0%, #733c64 100%);
  --bg-op: 0.1;
  --website-bg-02: linear-gradient(180deg, #733c64 0%, #4e3572 100%);
  --padding-text-mobile-home: 3rem;
}

h1 {
  font-size: 36px;
  color: white;
  font-family: var(--font-gilroy);
}

h2 {
  font-size: 36px;
  color: white;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 20px;
}

/* Background image for desktop devices */

Link {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #733b63ff;
}

.form-buttom-submit {
  border-radius: 200px;
  background: linear-gradient(90deg, #733c63 0%, #4a3373 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.body-top-section {
  position: relative;
  width: 100%;
  background: var(--bg-01);
  background-repeat: no-repeat;
}

.body-top-section::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
    --background,
    no-repeat center / cover;
  opacity: 0.1;
  /* Adjust the opacity value as needed */
}
.body-top-section-rotatewix {
  position: relative;
  width: 100%;
  background-color: white;
  background-repeat: no-repeat;
  z-index: 20;
  transition: background 1s ease;
}
.body-top-section-rotate {
  position: relative;
  width: 100%;
  background: var(--bg-02);
  background-repeat: no-repeat;
  z-index: 20;
  transition: background 1s ease;
}

.body-top-section-rotate::before {
  content: "";
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background) no-repeat center / cover;
  opacity: var(--bg-op);
  /* Adjust the opacity value as needed */
}

.body-top-section-rotate-3 {
  position: relative;
  width: 100%;
  background: var(--website-bg-02);
  background-repeat: no-repeat;
  z-index: 20;
}

.body-top-section-rotate-3-white {
  position: relative;
  width: 100%;
  background: white;
  background-repeat: no-repeat;
  z-index: 20;
}

.body-top-section-rotate-3::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
    --background,
    no-repeat center / cover;
  opacity: 0.1;
  /* Adjust the opacity value as needed */
}

/* Rest of your styles */
.gradient_website {
  background: linear-gradient(to bottom, #624da0, #984d7d);
  padding-left: 5px;
  padding-right: 5px;
}

.p-home {
  font-size: 1.5rem;
  color: white;
  line-height: 40px;
}

.p-home-black {
  font-size: 1.5rem;
  color: black;
  line-height: 40px;
}

.paragraph-text-services-offer {
  padding-top: 0.7rem;
  color: #fcfcfcfc;
  font-weight: 300;
  padding-bottom: 1.2rem;
  text-align: start;
  max-width: calc(100vw - 20vw);
}

.heading-text {
  font-size: 38px;
  text-align: start;
}

.heading-text-services {
  color: white;
  font-size: 38px;
  text-align: start;
  max-width: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  font-weight: 500 !important;
}

.mobile-header {
  display: none;
}

.mobile-header {
  display: flex;
  top: 0;
  z-index: 1000;
  background: white;
  max-height: 5.5rem;
  min-height: 5.5rem;
  overflow: hidden;
  width: 100%;
  width: 100vw;
}

.mobile-h-video {
  max-width: 100%;
  max-height: 10rem;
  overflow: hidden;
  min-width: 100vw;
}

.pl-mobile {
  padding-left: var(--padding-text-mobile-home);
}

.hero-section-container {
  clip-path: polygon(0 0, 0 72%, 100% 36%);
  background-color: white;
  height: 20rem;
  margin-top: -7.2rem;
}

.hero-section-container-main {
  clip-path: polygon(0 0, 0 72%, 100% 36%);
  background-color: white;
  height: 20rem;
  margin-top: -7.2rem;
}

.hero-section-container-transform-brand {
  clip-path: polygon(0 70%, 0% 100%, 100% 100%);
  background-color: white;
  height: 20rem;
  margin-top: -7.2rem;
}

.hero-section-container-transform-brand-headline {
  clip-path: polygon(0 70%, 0% 100%, 100% 100%);
  background-color: white;
  height: 7rem;
  margin-top: -7.2rem;
}

.heading-text-services-new {
  text-align: start;
  font-size: 25px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.desktop-hero-section-second {
  height: 100%;
}

.desktop-hero-section-second::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  /* Adjust the opacity value as needed */
}

.slider-object-image {
  /* transform: rotate(180deg);
     */
  width: 40vh;
}

.slider-object-image-h3 {
  color: white;
  position: absolute;
  top: calc(100vh - 85vh);
  left: calc(100vw - 90vw);
  font-size: 8vh;
  text-align: start;
  white-space: nowrap;
  transition: transform 1s ease-in-out;
}

.hero-form-container {
  top: calc(16vh - 200px);
  right: calc(80vw - 75vw + 1rem);
}

.slideTransition {
  transition: transform 1s ease-in-out;
}

@media (min-width: 1200px) {
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
}

.input-group {
  position: relative;
}

.input-group::after {
  /*content: "*";
  color: #f00; */
  position: absolute;
  top: 0px;
  left: var(--placeholder-text);
  width: 4px;
  height: 40px;
}

.select-group {
  position: relative;
}

.select-group::after {
  /*content: "*";
  color: #f00; */
  position: absolute;
  top: 5px;
  left: var(--placeholder-text);

}

.input-placeholder::placeholder {
  color: black;
  font-weight: 400;
}

.input-placeholder {
  color: black;
  font-weight: 400;
}
.input-placeholder2::placeholder {
  color: white;
  font-weight: 400;
}

.input-placeholder2 {
  color: white;
  font-weight: 400;
}
/* responsive mobile */

@media screen and (max-width: 768px) {
  body {
    position: relative;
    width: 100%;
    background: var(--main-bg-gradient-m);
    background-repeat: no-repeat;
    scroll-behavior: smooth;
  }

  .body::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:
      --background,
      no-repeat center / cover;
    opacity: 0.1;
    /* Adjust the opacity value as needed */
  }

  .body-top-section-rotate::before {
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    opacity: 0.1;
    /* Adjust the opacity value as needed */
  }

  h1 {
    font-size: 38px;
    color: white;
  }

  h2 {
    font-size: 25px;
  }

  .p-home {
    font-size: 1rem;
    color: white;
    line-height: 25px;
  }

  .p-home-black {
    font-size: 1rem;
    color: black;
    line-height: 25px;
  }

  .header-layout {
    min-height: auto;
    max-height: 9.5rem;
    background-color: white;
  }

  .hero-section-container-main {
    clip-path: polygon(0 0, 0 72%, 100% 36%);
    background-color: white;
    height: 20rem;
    margin-top: -7.2rem;
    margin-bottom: -89px;
  }

  .hero-form-container {
    top: calc(16vh - 200px);
    right: calc(80vw - 83vw + 1rem);
  }

  .user-card-style {
    width: calc(100% - 20px);
  }
}

/* responsive queries for Desktop */
@media screen and (min-width: 768px) {
  body {
    width: 100vw;
    min-height: 100vh;
    background: var(--main-bg-gradient-d);
    overflow-x: hidden;
  }

  .heading-text-services {
    color: white;
    font-size: 65px;
    text-align: start;
  }

  .heading-text {
    font-size: 65px;
    text-align: start;
  }

  .paragraph-text-services-offer {
    text-align: start;
  }
}

/* responsive queries for Tablets */
@media screen and (max-width: 814px) {
  .mobile-header {
    display: block;
  }
}


.transback {
  transition-timing-function: ease-in;
  transition-duration: 300ms;
  border: 1px solid #cfcdcd;
  background-color: #F3F3F3;
  color: black;
  border-radius: 8px;
 
}


.titillium-web-font{
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
}

.custom-buttonl {
  background: linear-gradient(to bottom, #634E9D, #924F7F);
  color: white;
  padding: 16px 32px; /* py-4 px-8 in Tailwind */
  margin-top: 16px; /* mt-4 in Tailwind */
  border-radius: 12px; /* rounded-lg in Tailwind */
  border: 1px solid white;
  font-size: 17px;
}

.custom-buttonl:hover {
  background: white;
  color: #634E9D;
}
.custom-buttonl3 {
  background: linear-gradient(to bottom, #634E9D, #924F7F);
  color: white;
  padding: 16px 32px; /* py-4 px-8 in Tailwind */
  margin-top: 16px; /* mt-4 in Tailwind */
  border-radius: 12px; /* rounded-lg in Tailwind */
  border: 1px solid white;
  font-size: 17px;
  width: 100%;
}

.custom-buttonl3:hover {
  background: white;
  color: #634E9D;
}

.custom-button2 {
  background: linear-gradient(to bottom, #634E9D, #924F7F);
  color: white;
  padding: 16px 32px; /* py-4 px-8 in Tailwind */
  margin-top: 16px; /* mt-4 in Tailwind */
  border-radius: 12px; /* rounded-lg in Tailwind */
  border: 1px solid white;
  font-size: 17px;
 
}

.custom-button2:hover {
  background: white;
  color: #634E9D;
}

.custom-button3 {
  background: linear-gradient(to bottom, #634E9D, #924F7F);
  color: white;
  padding: 16px 32px; /* py-4 px-8 in Tailwind */
  margin-top: 30px; /* mt-4 in Tailwind */
  border-radius: 12px; /* rounded-lg in Tailwind */
  border: 1px solid white;
  font-size: 17px;
  transition: background 0.3s ease, margin-top 0.3s ease, color 0.3s ease; /* Added smooth transitions */
}

.custom-button3:hover {
  background: linear-gradient(to bottom, #924F7F, #634E9D);
  color: white;
  padding: 16px 32px; /* py-4 px-8 in Tailwind */
  margin-top: 30px; /* mt-4 in Tailwind */
  border-radius: 12px; /* rounded-lg in Tailwind */
  border: 1px solid white;
  font-size: 17px;
}
.poppins-thin {
  font-family: "Poppins", sans-serif;

}

.karla-fons {
  font-family: "Karla", sans-serif;
}

.inter-fons {
  font-family: "Inter", sans-serif;
}

.swiper, swiper-container {
  /* margin-left: auto; */
  /* margin-right: auto; */
  width: 100% !important;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}


.react-multiple-carousel__arrow--left {
  left: calc(-1% + 4px) !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(-1% + 1px) !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #634E9D !important;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
   background: transparent !important; 
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
}